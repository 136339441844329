<template>
  <carousel :items-to-show="1" :breakpoints="breakpoints" :wrap-around="true" :pause-autoplay-on-hover="true" :autoplay="3000">
    <!--        <slide v-for="slide in 10" :key="slide">-->
    <!--            {{ slide }}-->
    <!--        </slide>-->
    <!--:autoplay="3000"-->

    <slide v-for="category in eventCategories" :key="category.id">
      <div class="carousel-slide-block horizontal-scrolling">
        <router-link :to="'/category/'+category.attributes.title.toLowerCase()">
          <!--<img class="thumbnail" src="../assets/images/categories/family-category-thumbnail.jpg">-->
          <img class="thumbnail" v-bind:src="api_url+category.attributes.imageThumbnail.data.attributes.url">
          <h3>{{ category.attributes.title }}</h3>
        </router-link>
      </div>
    </slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import axios from "axios";
// carousel ref: https://ismail9k.github.io/vue3-carousel/configs.html

export default {
  name: 'CategoriesCarousel',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_URL,
    api_auth_key: process.env.VUE_APP_API_AUTH_KEY,
    eventCategories: null,
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
        // 700px and up
        300: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        // 700px and up
        700: {
            itemsToShow: 3,
            snapAlign: 'center',
        },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    },
  }),
  methods: {
    getCategories(){
      // let thisEventID = this.$route.params.id; // TODO: Fix this parameter
      axios.get(this.api_url + '/api/event-categories?populate=*', {
        headers: {
          Authorization:
              'Bearer '+this.api_auth_key,
        },
      }).then(response => (
          // const update_events = response;
          // this.event = response.data.data.attributes,
              // this.debug = response.data.data,
            console.log(response.data.data),
            this.eventCategories = response.data.data
          // this.getEventTimeslots(this.eventID)
      ));

    },
  },
  created() {
    this.getCategories();
  }
}
</script>
