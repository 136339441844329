<template>
  <footer v-if="$route.name != 'comingSoon'" class="hidden-print">
    <section class="container top">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <router-link to="/">
                    <img alt="Vue logo" src="../assets/Photoppz-logo.svg">
                </router-link>
                <span class="special-text">Capturing life's priceless moments</span>
                <router-link to="/events" class="btn-red-filled">
                    <img src="../assets/icons/right-arrow-icon.svg" alt=""> Book a Session
                </router-link>
            </div>

            <div class="col-lg-3 col-md-6 col-12 second-col">
                <label class="heading">Discover</label>
                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/events">Events</router-link></li>
                    <li><router-link to="/category">Categories</router-link></li>
                    <li><router-link to="/">Testimonials</router-link></li>
                    <li><router-link to="/">Locations</router-link></li>
                </ul>
            </div>

            <div class="col-lg-3 col-md-6 col-12">
                <label for="" class="heading">Join Us</label>
                <ul>
                    <li><router-link to="/photographer-register">Photographer Recruitment</router-link></li>
                    <li><router-link to="/">Our Talent</router-link></li>
                    <li><p class="subscribe-text" @click.prevent="showModal = true">Newsletter Subscribe</p></li>
                </ul>
            </div>

            <div class="col-lg-3 col-md-6 col-12 fourth-col">
                <label for="" class="heading">Need Help?</label>
                <ul>
                    <li><router-link to="/faq">FAQs</router-link></li>
                    <li><router-link to="/">How it works</router-link></li>
                    <li class="icon-link">
                        <img class="vertically-middle" src="../assets/icons/call-icon.svg" alt="">
                        <a href="tel:+1-202-555-0119">+1-202-555-0119</a>
                    </li>
                    <li class="icon-link">
                        <img class="vertically-middle" src="../assets/icons/mail-icon.svg" alt="">
                        <a href="mailto:info@photoppz.com">info@photoppz.com</a>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <section class="container">
      <hr>
    </section>

    <section class="container bottom">
        <div class="row">
            <div class="col-lg-7 col-md-6">

                <ul class="bottom-links">
                    <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                    <li><img src="../assets/gray-circle.svg" alt=""></li>
                    <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                    <li><img src="../assets/gray-circle.svg" alt=""></li>
                    <li><router-link to="/cookie-policy">Cookies</router-link></li>
                </ul>

            </div>
            <div class="col-lg-5 col-md-6">

                <ul class="social-links">
                    <li>
                        <router-link to="/"><img src="../assets/icons/facebook-icon.svg" alt=""></router-link>
                    </li>
                    <li>
                        <router-link to="/"><img src="../assets/icons/linkedin-icon.svg" alt=""></router-link>
                    </li>
                    <li>
                        <router-link to="/"><img src="../assets/icons/instagram-icon.svg" alt=""></router-link>
                    </li>
                    <li>
                        <router-link to="/"><img src="../assets/icons/twitter-icon.svg" alt=""></router-link>
                    </li>
                </ul>

                <ul class="mobile-bottom-links bottom-links">
                    <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                    <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                    <li><router-link to="/cookie-policy">Cookies</router-link></li>
                </ul>

            </div>
        </div>
    </section>

  </footer>
  <transition name="modal">
    <mail-chimp-popup v-if="showModal" @close="showModal = false"></mail-chimp-popup>
  </transition>
</template>

<script>
// import NavMenu from '@/components/NavMenu'
import MailChimpPopup from '@/components/MailChimpPopup.vue';

export default {
  name: 'SiteFooter',
  data: () => ({
    showModal: false,
  }),
  props: {
    msg: String
  },
  components: {
    MailChimpPopup
  },
  methods: {
    showMailchimpPopup() {
      // Access the showMailchimpPopup method from the MailchimpPopup component
      this.$refs.mailchimpPopup.showMailchimpPopup();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subscribe-text {
    line-height: 2.2;
    color: #2F242C;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin: 0px;
}
</style>
